import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { StateService } from '../../shared/services/state.service';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(@Inject(DOCUMENT) private document: Document, private readonly state: StateService) {}
  setLightModeColor() {
    this.document.documentElement.style.setProperty('--md-color-1', '#ffffff');
    this.document.documentElement.style.setProperty('--md-color-2', '#122e36');
    this.document.documentElement.style.setProperty('--md-color-3', '#a35199');
    this.document.documentElement.style.setProperty('--md-color-4', '#682d61');
    this.document.documentElement.style.setProperty('--md-color-5', '#eef0f1');
    this.document.documentElement.style.setProperty('--md-color-6', '#d7dcdd');
    this.document.documentElement.style.setProperty('--md-color-7', '#a8b2b5');
    this.document.documentElement.style.setProperty('--md-color-8', '#495e65');
    this.document.documentElement.style.setProperty('--md-box-shadow', '0 0 2.4rem 0 rgba(0, 0, 0, 0.075)');
    this.document.documentElement.style.setProperty('--md-spinner-background-color', 'rgba(255,255,255, 0.75)');
    this.document.documentElement.style.setProperty('--md-arrowImage1', 'url(/assets/img/arrowImage1.svg)');
    this.document.documentElement.style.setProperty('--md-arrowImage2', 'url(/assets/img/arrowImage2.svg)');
    this.document.documentElement.style.setProperty('--md-arrowImage3', 'url(/assets/img/arrowImage3.svg)');
    this.document.documentElement.style.setProperty('--md-arrowImage4', 'url(/assets/img/arrowImage4.svg)');
    this.document.documentElement.style.setProperty('--maskbg', 'rgba(255, 255, 255, 0.75)');
  }
  setDarkModeColor() {
    this.state.appColorMode.pipe(take(1)).subscribe((colorMode: number) => {
      switch (colorMode) {
        case 1:
          this.document.documentElement.style.setProperty('--md-color-1', '#1d1d1d');
          this.document.documentElement.style.setProperty('--md-color-2', '#ffffff');
          this.document.documentElement.style.setProperty('--md-color-3', '#a35199');
          this.document.documentElement.style.setProperty('--md-color-4', '#682d61');
          this.document.documentElement.style.setProperty('--md-color-5', '#2d2d2d');
          this.document.documentElement.style.setProperty('--md-color-6', '#434343');
          this.document.documentElement.style.setProperty('--md-color-7', '#707070');
          this.document.documentElement.style.setProperty('--md-color-8', '#cbcbcb');
          this.document.documentElement.style.setProperty('--md-box-shadow', '0 0 2.4rem 0 rgba(0, 0, 0, 0.5)');
          this.document.documentElement.style.setProperty('--md-spinner-background-color', 'rgba(0, 0, 0, 0.35)');
          this.document.documentElement.style.setProperty('--md-arrowImage1', 'url(/assets/img/arrowImage1_dark.svg)');
          this.document.documentElement.style.setProperty('--md-arrowImage2', 'url(/assets/img/arrowImage2_dark.svg)');
          this.document.documentElement.style.setProperty('--md-arrowImage3', 'url(/assets/img/arrowImage3_dark.svg)');
          this.document.documentElement.style.setProperty('--md-arrowImage4', 'url(/assets/img/arrowImage4_dark.svg)');
          this.document.documentElement.style.setProperty('--maskbg', 'rgba(0, 0, 0, 0.5)');
          break;
        case 2:
          this.document.documentElement.style.setProperty('--md-color-1', '#122e36');
          this.document.documentElement.style.setProperty('--md-color-2', '#ffffff');
          this.document.documentElement.style.setProperty('--md-color-3', '#a35199');
          this.document.documentElement.style.setProperty('--md-color-4', '#682d61');
          this.document.documentElement.style.setProperty('--md-color-5', '#233d44');
          this.document.documentElement.style.setProperty('--md-color-6', '#3a5158');
          this.document.documentElement.style.setProperty('--md-color-7', '#697b80');
          this.document.documentElement.style.setProperty('--md-color-8', '#c8cfd0');
          this.document.documentElement.style.setProperty('--md-box-shadow', '0 0 2.4rem 0 rgba(0, 0, 0, 0.5)');
          this.document.documentElement.style.setProperty('--md-spinner-background-color', 'rgba(0, 0, 0, 0.35)');
          this.document.documentElement.style.setProperty('--md-arrowImage1', 'url(/assets/img/arrowImage1_dark.svg)');
          this.document.documentElement.style.setProperty('--md-arrowImage2', 'url(/assets/img/arrowImage2_dark.svg)');
          this.document.documentElement.style.setProperty('--md-arrowImage3', 'url(/assets/img/arrowImage3_dark.svg)');
          this.document.documentElement.style.setProperty('--md-arrowImage4', 'url(/assets/img/arrowImage4_dark.svg)');
          this.document.documentElement.style.setProperty('--maskbg', 'rgba(0, 0, 0, 0.5)');
          break;
        case 3:
          this.document.documentElement.style.setProperty('--md-color-1', '#111101');
          this.document.documentElement.style.setProperty('--md-color-2', '#ffffff');
          this.document.documentElement.style.setProperty('--md-color-3', '#a35199');
          this.document.documentElement.style.setProperty('--md-color-4', '#682d61');
          this.document.documentElement.style.setProperty('--md-color-5', '#222213');
          this.document.documentElement.style.setProperty('--md-color-6', '#39392c');
          this.document.documentElement.style.setProperty('--md-color-7', '#69695f');
          this.document.documentElement.style.setProperty('--md-color-8', '#c8c8c4');
          this.document.documentElement.style.setProperty('--md-box-shadow', '0 0 2.4rem 0 rgba(0, 0, 0, 0.5)');
          this.document.documentElement.style.setProperty('--md-spinner-background-color', 'rgba(0, 0, 0, 0.35)');
          this.document.documentElement.style.setProperty('--md-arrowImage1', 'url(/assets/img/arrowImage1_dark.svg)');
          this.document.documentElement.style.setProperty('--md-arrowImage2', 'url(/assets/img/arrowImage2_dark.svg)');
          this.document.documentElement.style.setProperty('--md-arrowImage3', 'url(/assets/img/arrowImage3_dark.svg)');
          this.document.documentElement.style.setProperty('--md-arrowImage4', 'url(/assets/img/arrowImage4_dark.svg)');
          this.document.documentElement.style.setProperty('--maskbg', 'rgba(0, 0, 0, 0.5)');
          break;
      }
    });
  }
}
