import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject, map, take } from 'rxjs';
import { user } from '../models/common.model';
import { SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private user$ = new BehaviorSubject<user>(null);
  get user(): Observable<user> {
    return this.user$.asObservable();
  }
  setUser(user: user) {
    this.user$.next(user);
  }
  get isAuthenticated() {
    return this.user.pipe(map((user) => (user ? true : false)));
  }
  get isAdmin() {
    return this.user.pipe(map((user) => (user && user.isadmin == 1 ? true : false)));
  }
  private userAvatar$ = new BehaviorSubject<SafeUrl>(null);
  get userAvatar(): Observable<SafeUrl> {
    return this.userAvatar$.asObservable();
  }
  setUserAvatar(avatar: SafeUrl) {
    this.userAvatar$.next(avatar);
  }
  private toaster$ = new Subject<any>();
  get toaster(): Observable<any> {
    return this.toaster$.asObservable();
  }
  setToaster(data: any) {
    this.toaster$.next(data);
  }
  private lightModeOn$ = new BehaviorSubject<boolean>(false);
  get lightModeOn(): Observable<boolean> {
    return this.lightModeOn$.asObservable();
  }
  setLightModeOn(lightModeOn: boolean) {
    this.lightModeOn$.next(lightModeOn);
    localStorage.setItem('lightModeOn', lightModeOn.toString());
  }
  private appColorMode$ = new BehaviorSubject<number>(0);
  get appColorMode(): Observable<number> {
    return this.appColorMode$.asObservable();
  }
  setAppColorMode(colorMode: number) {
    this.appColorMode$.next(colorMode);
  }
  private appOverFlowHidden$ = new BehaviorSubject<boolean>(false);
  get appOverFlowHidden(): Observable<boolean> {
    return this.appOverFlowHidden$.asObservable();
  }
  setAppOverFlowHidden(hidden: boolean) {
    this.appOverFlowHidden$.next(hidden);
  }
  sendAnalyticsEvent(eventName: string, eventParameters: any = {}) {
    this.user.pipe(take(1)).subscribe((user) => {
      if (
        user != null &&
        (user.email.includes('@makedata.ai') ||
          user.email.includes('@objectsystems.com') ||
          user.email.includes('@cleancodefactory.de'))
      ) {
        return;
      }
      gtag('event', eventName, eventParameters);
    });
  }
}
