import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, Subscription, take } from 'rxjs';
import { StateService } from './modules/shared/services/state.service';
import { HomeService } from './modules/home/services/home.service';
import { HomeWelcomeComponent } from './modules/shared/components/home-welcome/home-welcome.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  checkInterval: any;
  showToaster$: Subject<any> = new Subject();
  subscriptions: Subscription[] = [];
  previousRoute: string;
  appOverFlowHidden$: Observable<boolean>;
  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly router: Router,
    private readonly state: StateService,
    private readonly homeService: HomeService
  ) {
    this.appOverFlowHidden$ = this.state.appOverFlowHidden;
    if (localStorage.getItem('lightModeOn') != null) {
      let check = localStorage.getItem('lightModeOn') === 'true';
      this.state.setLightModeOn(check);
      if (check) {
        this.homeService.setLightModeColor();
      } else {
        this.homeService.setDarkModeColor();
      }
    }
    this.subscriptions.push(
      this.state.toaster.subscribe((data: any) => {
        this.showToaster$.next(data);
      }),
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (this.previousRoute != event.urlAfterRedirects.split('?')[0]) {
            this.previousRoute = event.urlAfterRedirects.split('?')[0];
            this.state.sendAnalyticsEvent('App:PageNavigation', { app_url: this.previousRoute });
          }
          this.state.appColorMode.pipe(take(1)).subscribe((colorMode: number) => {
            let newColorMode = null;
            if (
              event.urlAfterRedirects == '/app' ||
              event.urlAfterRedirects == '/app/admin' ||
              event.urlAfterRedirects == '/app/account'
            ) {
              newColorMode = 1;
            }
            if (event.urlAfterRedirects.includes('/app/synthea')) {
              newColorMode = 2;
            }
            if (event.urlAfterRedirects.includes('/app/healthcare-data-builder')) {
              newColorMode = 3;
            }
            if (newColorMode != null && colorMode != newColorMode) {
              this.state.setAppColorMode(newColorMode);
            }
            this.state.lightModeOn.pipe(take(1)).subscribe((lightModeOn) => {
              if (!lightModeOn && colorMode != newColorMode) {
                this.homeService.setDarkModeColor();
              }
            });
          });
        }
      })
    );
  }
  ngOnInit() {
    this.check();
    this.checkInterval = setInterval(() => this.check(), 5000);
  }
  check() {
    this.state.isAuthenticated.pipe(take(1)).subscribe((check) => {
      if (!check) {
        if (this.viewContainerRef.length != 0) {
          this.viewContainerRef.clear();
        }
        this.viewContainerRef.createComponent(HomeWelcomeComponent);
      } else {
        if (this.viewContainerRef.length != 0) {
          this.viewContainerRef.clear();
        }
      }
    });
  }
  ngOnDestroy() {
    clearInterval(this.checkInterval);
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
