import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StateService } from './state.service';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(private readonly state: StateService) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          this.state.setUser(null);
          this.state.setUserAvatar(null);
          window.location.href = '/account/signin';
        } else {
          //TODO: Think on how to handle other errors
        }
        return throwError(() => error);
      })
    );
  }
}
