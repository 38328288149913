import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'md-button',
  templateUrl: './md-button.component.html',
  styleUrls: ['./md-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdButtonComponent {
  @Input('Style') style: 'primary' | 'secondary' = 'primary';
  @Input('Size') size: 'small' | 'large' = 'large';
  @Input('Type') type: 'button' | 'submit' | 'reset' = 'button';
  @Input('Loading') loading: boolean = false;
  @Input('Disabled') disabled: boolean = false;
  @Input('TooltipText') tooltipText: string = null;
  @Input('Form') form: string;
  @Output('Click') click: EventEmitter<any> = new EventEmitter();
  onClick(event: any) {
    this.click.emit(event);
  }
}
