import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'md-modal',
  templateUrl: './md-modal.component.html',
  styleUrls: ['./md-modal.component.scss'],
})
export class MdModalComponent {
  @Input() show: boolean = false;
  @Input('Width') width: number = 10;
  @Input('Closable') closable: boolean = false;
  @Input('NoAnimation') noAnimation: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter();
  @Output('Hide') hide: EventEmitter<void> = new EventEmitter();
  @ContentChild('Header') header: TemplateRef<any>;
  @ContentChild('Body') body: TemplateRef<any>;
  onHide() {
    this.show = false;
    this.showChange.emit(false);
    this.hide.emit();
  }
}
