<md-modal [show]="true" [Width]="550" [NoAnimation]="true">
  <ng-template #Header>
    <h2 class="md-heading-2">
      Generate, browse, and download realistic
      <span style="color: var(--md-color-3)">synthetic healthcare test data.</span>
    </h2>
  </ng-template>
  <ng-template #Body>
    <div class="hw">
      <div class="hw__subtitle md-large-text-bold">
        Easy
        <span class="hw__subtitle md-large-text">to use,</span>
        easy
        <span class="hw__subtitle md-large-text">to love.</span>
      </div>
      <div class="hw__line"></div>
      <div class="hw__content-container">
        <div class="hw__content">
          <div class="hw__content-icon"></div>
          <div class="hw__content-text md-large-text-bold">
            Welcome! <br>
            <span class="md-large-text">Please</span>
            sign in or create a free account
            <span class="md-large-text">to use</span>
            Make<span class="md-large-text">Data.ai</span>
          </div>
        </div>
      </div>
      <div class="hw__line"></div>
      <div class="hw__buttons">
        <md-button [Style]="'secondary'" (Click)="onClickSignIn()" [id]="'WelcomeSignInButton'">
          Sign In
        </md-button>
        <md-button (Click)="onClickCreate()" [id]="'WelcomeCreateButton'">
          Create FREE Account
        </md-button>
      </div>
    </div>
  </ng-template>
</md-modal>