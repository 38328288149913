import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StateService } from './state.service';
import { environment } from 'src/environments/environment';
import { responseModel } from '../models/common.model';
import { DomSanitizer } from '@angular/platform-browser';
import * as shortUuid from 'short-uuid';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private host: string = environment.host;
  constructor(
    private readonly httpClient: HttpClient,
    private readonly domSanitizer: DomSanitizer,
    private readonly state: StateService
  ) {}
  getUserInfo() {
    const endPoint = this.host + '/node/users/main';
    return this.httpClient.get<responseModel<any>>(endPoint);
  }
  getUserAvatar(userName: string) {
    let uuid = shortUuid.generate();
    const endPoint = this.host + '/node/users/account/avatar?username=' + userName + '&uuid=' + uuid;
    return this.httpClient.get<responseModel<Blob>>(endPoint, {
      responseType: 'blob' as 'json',
      headers: { 'blob-response': '' },
    });
  }
  loadUserInfo() {
    return new Promise((resolve, reject) => {
      this.getUserInfo().subscribe({
        next: (response) => {
          if (response.status.issuccessful && response.data && response.data.userid) {
            this.state.setUser(response.data);
            this.state.sendAnalyticsEvent('App:Authenticated');
            this.getUserAvatar(response.data.userid).subscribe({
              next: (response) => {
                if (response instanceof Blob && response.size != 0 && response.type != 'text/xml') {
                  let blobURL = URL.createObjectURL(response);
                  let sanitizedBlobUrl = this.domSanitizer.bypassSecurityTrustUrl(blobURL);
                  this.state.setUserAvatar(sanitizedBlobUrl);
                } else {
                  this.state.setUserAvatar(null);
                }
                const appLoadingScreen = document.getElementById('app-loading-screen');
                if (appLoadingScreen) {
                  appLoadingScreen.style.display = 'none';
                }
                resolve(true);
              },
              error: () => {
                this.state.setUserAvatar(null);
                const appLoadingScreen = document.getElementById('app-loading-screen');
                if (appLoadingScreen) {
                  appLoadingScreen.style.display = 'none';
                }
                resolve(true);
              },
            });
          } else {
            this.state.setUser(null);
            this.state.setUserAvatar(null);
            const appLoadingScreen = document.getElementById('app-loading-screen');
            if (appLoadingScreen) {
              appLoadingScreen.style.display = 'none';
            }
            resolve(true);
          }
        },
        error: () => {
          this.state.setUser(null);
          this.state.setUserAvatar(null);
          const appLoadingScreen = document.getElementById('app-loading-screen');
          if (appLoadingScreen) {
            appLoadingScreen.style.display = 'none';
          }
          resolve(true);
        },
      });
    });
  }
}
