import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

interface toasterData {
  severity: 'success' | 'info' | 'warn' | 'error';
  detail: string;
  position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' | 'top-center' | 'bottom-center' | 'center';
}

@Component({
  selector: 'md-toaster',
  templateUrl: './md-toaster.component.html',
  styleUrls: ['./md-toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class MdToasterComponent implements OnInit, OnDestroy {
  @Input('Key') key: string = '';
  @Input('Position') position:
    | 'top-right'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-left'
    | 'top-center'
    | 'bottom-center'
    | 'center' = 'top-right';
  @Input('Show') show$: Subject<toasterData>;
  life: number = 3000;
  showSubscription: Subscription;
  constructor(private messageService: MessageService) {}
  ngOnInit() {
    this.showSubscription = this.show$.subscribe((show) => {
      if (show.position) {
        this.position = show.position;
      }
      this.messageService.add({ key: this.key, severity: show.severity, detail: show.detail, sticky: false });
    });
  }
  ngOnDestroy() {
    this.showSubscription?.unsubscribe();
  }
}
