<button [type]="type"
  [ngClass]="[ 'md-component-text', style, size, disabled ? 'disabled' : '', loading ? 'loading' : '' ]"
  [disabled]="disabled || loading" (click)="onClick($event)" class="md-button" [pTooltip]="tooltipText"
  [tooltipPosition]="'bottom'" [tooltipStyleClass]="'md-field-tooltip'" [attr.form]="form">
  <div [ngClass]="loading ? 'hidden' : ''">
    <ng-content></ng-content>
  </div>
  <div class="md-button__spinner" *ngIf="loading">
    <span class="md-button__spinner-icon"></span>
  </div>
</button>