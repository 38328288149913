import { Component } from '@angular/core';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'home-welcome',
  templateUrl: './home-welcome.component.html',
  styleUrls: ['./home-welcome.component.scss'],
})
export class HomeWelcomeComponent {
  constructor(private readonly state: StateService) {}
  onClickSignIn() {
    this.state.sendAnalyticsEvent('App:AccountSignIn');
    window.location.href = '/account/signin';
  }
  onClickCreate() {
    this.state.sendAnalyticsEvent('App:AccountCreate');
    window.location.href = '/account/signin';
  }
}
